import React from 'react';
import { Layout, SEO } from '../layout';
import {
	FadeInWhenVisibleLeft,
	FadeInWhenVisibleRight,
} from '../components/FadeInWhenVisible';

import 'swiper/css';
import 'swiper/css/navigation';

import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';

SwiperCore.use([Navigation]);
const CarouselImages = () => {
	return (
		<>
			<Swiper
				pagination={{
					type: 'fraction',
				}}
				navigation={true}
				className="mySwiper">
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img5.jpg"
						alt="imagem com MirePi com tinta vermelha sobre o corpo e de olhos fechados"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img4.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img3.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img6.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img7.jpg"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img8.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img9.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
				<SwiperSlide>
					{' '}
					<img
						src="../images/Mamilos/img10.JPG"
						alt="imagem de fundo azul e mirepi performando"
					/>
				</SwiperSlide>
			</Swiper>
		</>
	);
};

function PageJobMml() {
	const fadeLeft = {
		hidden: { opacity: 0, y: 100 },
		visible: { opacity: 1, y: 0 },
	};
	return (
		<Layout>
			<SEO title="Mamilos" />
			<section className="section-padding">
				<div className=" flow">
					<section className="container_page_jobs">
						<motion.h1
							className="title_page"
							variants={fadeLeft}
							initial="hidden"
							animate="visible"
							transition={{ duration: 1 }}>
							Mamilos
						</motion.h1>

						<motion.img
							whileTap={{ scale: 0.9 }}
							drag={true}
							dragConstraints={{
								left: 0,
								right: 250,
								top: 0,
								bottom: 50,
							}}
							initial={{ opacity: 0, y: -100 }}
							animate={{
								opacity: 1,
								y: 0,
								transition: { duration: 1 },
							}}
							className="first_image"
							src="../images/Mamilos/img2.jpg"
						/>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									Mamilos é um espetáculo que transita entre a
									dança e a performance realizado pela
									Coletiva Corpatômica em 2019, com
									apresentações no Sesc das Artes, Sesc Aldeia
									Arapiraca, entre outros lugares em Maceió.
									Em cena, são colocadas experiências
									particulares de corpos que enfrentam
									situações de violência e assédio em lugares
									públicos.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<FadeInWhenVisibleLeft>
							<div className="container_text_job">
								<img
									className="second_image"
									src="../images/Mamilos/img1.jpg"
								/>
							</div>
						</FadeInWhenVisibleLeft>
						<FadeInWhenVisibleRight>
							<div className="container_text_job">
								<p className="text">
									Com os seios expostos, estes corpos buscam
									táticas de sobrevivência e confrontam o
									olhar daqueles que assistem e violam suas
									existências. Em estado voraz, os corpos
									sangram e resistem à sociedade conservadora.
									É um trabalho que está sempre em movimento,
									levantando questionamentos sobre o corpo,
									violências geradas e vivências
									autobiográficas pautadas em questões de
									gênero e auto identificação.
								</p>
							</div>
						</FadeInWhenVisibleRight>
						<CarouselImages />
						<div className="infos">
							<p className="text_infos"></p>
							<img className="image_infos"></img>
						</div>
					</section>
				</div>
				<div className="section_infos">
					<section className="container_infos">
						<p className="text_bold">Ficha técnica:</p>
						<div className="texts_infos">
							<p className="text_bold">Intérpretes Criadores:</p>
							<p className="text_indent">
								Maria França , Mirê Pi y Ayò Ribeiro
							</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Provocadora Cênica:</p>
							<p className="text_indent">Tayná Nogueira</p>
						</div>
						<div className="texts_infos">
							<p className="text_bold">Produção:</p>
							<p className="text_indent">Coletiva Corpatômica</p>
						</div>
						
						<br />
						{/*<p className="text_bold">Release:</p>
						<p className="text_release">
							{' '}
							Mamilos é um espetáculo que transita entre a dança e
							a performance realizado pela Coletiva Corpatômica em
							2019, com apresentações no Sesc das Artes, Sesc
							Aldeia Arapiraca, entre outros lugares em Maceió. Em
							cena, são colocadas experiências particulares de
							corpos que enfrentam situações de violência e
							assédio em lugares públicos. Com os seios expostos,
							estes corpos buscam táticas de sobrevivência e
							confrontam o olhar daqueles que assistem e violam
							suas existências. Em estado voraz, os corpos sangram
							e resistem à sociedade conservadora. É um trabalho
							que está sempre em movimento, levantando
							questionamentos sobre o corpo, violências geradas e
							vivências autobiográficas pautadas em questões de
							gênero e auto identificação.
						</p> */}
					</section>
				</div>
			</section>
		</Layout>
	);
}

export default WithTransition(PageJobMml);
